import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "../../common/components/ui/button";
import { useAppSelector } from "../../hooks";
import { PROJECT_STATUS, USER_TYPES } from "../../lib/constants";
import { useProjectAPI } from "../../api/project_api";
import { useDispatch } from "react-redux";
import { updateProjects, updateProjectsSubmittedForApprovalCount } from "../project/projectsSlice";
import { projectWizardSlice } from "../../pages/project/projectWizardSlice";
import "./header.css";
import useClickOutside from "../../hooks/click_outside";
import { useNavigate } from "react-router-dom";

const PublicHeader = ({ minimalHeader, theme, setOpacity }: { minimalHeader?: boolean, theme?: "dark" | "lite", setOpacity?: boolean }) => {
    const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
    const { profile } = useAppSelector((state: any) => state.user);
    const [scrollTop, setScrollTop] = useState(0);
    const [navOpen, setNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeLink, setActiveLink] = useState<string | null>(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { resetProjectWizard } = projectWizardSlice.actions;
    const { fetchProjects } = useProjectAPI();
    const menuRef = useRef<HTMLDivElement>(null);
    const createMenuRef = useRef<HTMLUListElement>(null);
    useClickOutside(menuRef, () => { setNavOpen(false) });
    useClickOutside(createMenuRef, () => { setIsDropdownOpen(false) });


    const handleLogout = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    useEffect(() => {
        const handleScroll = (event: any) => {
            setScrollTop(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchProjects().then((projects) => {
                dispatch(updateProjects(projects));
                const count = projects.filter(
                    (p) =>
                        p.status &&
                        [PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key, PROJECT_STATUS.CHANGES_REQUESTED.key].includes(p.status)
                )?.length;
                dispatch(updateProjectsSubmittedForApprovalCount(count));
            });
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("/dashboard")) {
            setActiveLink("create");
        } else if (path.includes("/check-license")) {
            setActiveLink("protect");
        } else {
            setActiveLink(null);
        }
    }, [window.location.pathname]);

    const colorTheme = useMemo(() => {
        return scrollTop > 10 ? "lite" : theme || "lite";
    }, [scrollTop, theme])

    const isMarketingPage = window.location.pathname.includes("/for-marketers") || window.location.pathname.includes("/for-talent") || window.location.pathname == ("/");

    const isImageCreatorRole = profile.roles.includes("AI_IMAGE_CREATOR");
    if (isLoading) {
        return null;
    }

    return (
        <div>
            <header className={"nav_header " + colorTheme + " " + ((scrollTop > 10) || (setOpacity) ? "scroll_background" : "") + (minimalHeader ? " minimal_header" : "")}>
                <button className="menu_button" onClick={() => setNavOpen((old) => !old)}>{navOpen ? <XMarkIcon width={32} /> : <Bars3Icon width={32} />}</button>
                {navOpen && <div className="mobile_nav" ref={menuRef}>
                    <ul className="mobile_nav_links">
                        {isAuthenticated && profile.user_type === USER_TYPES.RIGHTS_HOLDER.key && <li><a href="/add-talent">Add talent</a></li>}
                        {isAuthenticated && profile.user_type === USER_TYPES.CREATOR.key && <li><a href="/project/new">Create project</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/check-license">Verify license</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/for-talent">For Talent</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/for-marketers">For Marketers</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/blog">Blog</a></li>}
                        {(isAuthenticated && isImageCreatorRole) && <li><a href="/dashboard">Create image</a></li>}
                        {isAuthenticated && <li><a href="/dashboard">Dashboard</a></li>}
                        {!isAuthenticated && <li><a href="/register">Get started</a></li>}
                        {!isAuthenticated && <li><a href="#" onClick={() => loginWithRedirect()}>Log in</a></li>}
                        {isAuthenticated && <li><a href="#" onClick={handleLogout}>Log out</a></li>}
                    </ul>
                </div>}
                <a href={isMarketingPage ? "/" : "/dashboard"} className="logo"><img
                    src={colorTheme === "dark" ? "/officialai_logo_black.svg" : "/officialai_logo_white.svg"}
                    alt="logo" /></a>
                {!minimalHeader && <>
                    {(!isAuthenticated || isMarketingPage) && <ul className="nav_links">
                        <li><a href="/check-license">Verify license</a></li>
                        <li><a href="/for-talent">For Talent</a></li>
                        <li><a href="/for-marketers">For Marketers</a></li>
                        <li><a href="/blog">Blog</a></li>
                        {isAuthenticated && <li><a href="/dashboard">Dashboard</a></li>}
                    </ul>}
                    {!isAuthenticated && <ul className="nav_actions">
                        <li><a href="/register" className={"pill_button " + theme}>Get started</a></li>
                        <li><a href={"#"} onClick={() => loginWithRedirect()}>Log in</a></li>
                    </ul>}
                    {isAuthenticated && (
                        <ul className="nav_actions">
                            {isImageCreatorRole && (
                                <div className="flex flex-row gap-8">
                                    <li className={`hover:cursor-pointer ${activeLink === "create" ? "border-b-2" : ""}`}>
                                        <a onClick={() => navigate("/dashboard")}>
                                            Create
                                        </a>
                                    </li>
                                    <li className={`hover:cursor-pointer ${activeLink === "protect" ? "border-b-2" : ""}`}>
                                        <a onClick={() => navigate("/check-license")}>
                                            Protect
                                        </a>
                                    </li>
                                </div>
                            )}
                            {(!isImageCreatorRole && profile.user_type === USER_TYPES.CREATOR.key) && (
                                <li>
                                    <a
                                        onClick={() => {
                                            dispatch(resetProjectWizard());
                                            navigate("/project/new");
                                        }}
                                    >
                                        Create project
                                    </a>
                                </li>
                            )}
                            {(!isImageCreatorRole && profile.user_type === USER_TYPES.RIGHTS_HOLDER.key) && (
                                <li>
                                    <a onClick={() => navigate("/add-talent")}>
                                        Add talent
                                    </a>
                                </li>
                            )}
                            <li>
                                <a href="#" onClick={handleLogout}>
                                    Log out
                                </a>
                            </li>
                        </ul>)}
                </>}

            </header>
        </div>
    )
}

export default PublicHeader;