import React, {useState, useEffect, useCallback} from "react";
import { DialogModal } from "../../../../common/components/ui/dialog_modal";
import Heading from "../../../../common/components/heading";
import ImageEditCanvas from "./image_edit_canvas";
import { GenerativeOutput, GenerativeModel } from "../../models/image_generator";
import { Button } from "../../../../common/components/ui/button";
import { UndoArrow, Sparkles, InfoIcon } from "../../../../common/icons/icons";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../common/components/ui/popover";
import VaultDropdown from "../../components/vault_dropdown";
import loading from "../../../../common/components/ui/loading.gif";
import { useGenerativeAPI } from "../../../../api/generative_ai_api";
import { useUserProductAPI } from "../../../../api/user_product_api";
import { File } from "../../../../model/file";
import ImageDropdown from "../image_dropdown";

const InpaintModal = ({ isInpaintModalOpen, setIsInpaintModalOpen, selectedVariant, modelList, generateInpaint }: {
    isInpaintModalOpen: boolean,
    setIsInpaintModalOpen: (isOpen: boolean) => void,
    selectedVariant?: GenerativeOutput,
    modelList: GenerativeModel[],
    generateInpaint: (prompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null, inpaintFileId: string | null) => void
}) => {
    const [cursorSize, setCursorSize] = useState(32);
    const [drawnPortions, setDrawnPortions] = useState<{ x: number; y: number; radius: number }[][]>([]);
    const [inpaintPrompt, setInpaintPrompt] = useState<string>("");
    const [mask, setMask] = useState<string | null>(null);
    const [isPromptLoading, setIsPromptLoading] = useState(false);
    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null);
    const { fetchFilesByUsage } = useUserProductAPI();
    const [ inpaintFiles, setInpaintFiles ] = useState<File[]>([]);
    const [ selectedInpaintFile, setSelectedInpaintFile ] = useState<File | null>(null);

    useEffect(() => {
        const prompt = inpaintPrompt || "";

        if (!selectedModel) return;
        if (!prompt.includes(selectedModel?.generative_tag)) {
            setSelectedModel(null);
            setSelectedModelId("");
        }
    }, [inpaintPrompt]);

    useEffect(() => {
        if (selectedModel) {
            if(selectedModel.entity_type === "USER_PRODUCT") {
                fetchFilesByUsage({id: selectedModel.entity_id, usage: "inpaint_asset"}).then((files) => {
                    setInpaintFiles(files);
                    setSelectedInpaintFile(null);
                });
            }else{
                setSelectedInpaintFile(null);
                setInpaintFiles([]);
            }
        }
    }, [selectedModel]);

    const [selectedModelId, setSelectedModelId] = useState("");

    const { enhancePrompt } = useGenerativeAPI();

    const increaseCursorSize = () => {
        setCursorSize((prevSize) => prevSize + 10);
    }

    const decreaseCursorSize = () => {
        setCursorSize((prevSize) => Math.max(prevSize - 10, 10));
    }

    const undoLastDraw = () => {
        setDrawnPortions((prev) => prev.slice(0, -1));
    };

    const resetCanvas = () => {
        setDrawnPortions([]);
    };

    const generateMask = (base64Mask: any) => {
        setMask(base64Mask);
    }

    const enhanceTextPrompt = async () => {
        setIsPromptLoading(true);

        const models = [];
        models.push(selectedModelId);

        const response = await enhancePrompt(inpaintPrompt, models);
        if (response && response.enhanced) {
            setInpaintPrompt(response.enhanced);
        }
        setIsPromptLoading(false);
    }

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        setSelectedModelId(model?.id || "");
        const regex = /#\w+/g;
        setInpaintPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${promptWithoutOldTag} ${newGenerativeTag}`.trim();

            return updatedPrompt;
        });
    }
    const handleSelectInpaintFile = (model: File | null) => {
        setSelectedInpaintFile(model);
    }

    const triggerGenerateInpaint = useCallback(() => {
        const modelWeights = [];
        if (selectedModel?.entity_type === "LICENSABLE_PROPERTY") {
            modelWeights.push({ id: selectedModel.id, weight: 0.8 });
        }
        if (selectedModel?.entity_type === "USER_PRODUCT") {
            modelWeights.push({ id: selectedModel.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
        }

        generateInpaint(
            inpaintPrompt,
            modelWeights,
            mask,
            selectedInpaintFile?.id || null
        )
        resetCanvas();
        setInpaintPrompt("");
        setIsInpaintModalOpen(false);
    }, [selectedModel, inpaintPrompt, mask, selectedInpaintFile]);

    return (
        <DialogModal
            isOpen={isInpaintModalOpen}
            onOpenChange={setIsInpaintModalOpen}
            onClose={() => { setIsInpaintModalOpen(false); resetCanvas(); }}
            variant={inpaintFiles.length > 0 ? "xlarge":"large"}
            className="p-8"
        >
            <div className="flex flex-row gap-7">
                <ImageEditCanvas
                    cursorSize={cursorSize}
                    width={600}
                    drawnPortion={drawnPortions}
                    setDrawnPortion={setDrawnPortions}
                    handleMaskGenerated={generateMask}
                    image={selectedVariant?.permalink ?? ""} />
                <div className="flex flex-col text-left gap-3">
                    <Heading as="h3">Click and paint to get started.</Heading>
                    <p className="font-semibold">Image controls</p>
                    <div className="flex flex-row gap-2">
                        <Button variant="outline-official" onClick={resetCanvas} className="rounded-lg border-[1px] border-gray-300 font-normal p-4 w-[53px] h-9 bg-transparent hover:bg-black fill-black stroke-black hover:text-white">Reset</Button>
                        <Button variant="outline-official" onClick={undoLastDraw} className="rounded-lg border-[1px] border-gray-300 p-4 w-9 h-9 bg-transparent hover:bg-black fill-black hover:fill-white"><div className="flex-shrink-0"><UndoArrow /></div></Button>
                    </div>
                    <p className="font-semibold">Brush size</p>
                    <div className="flex gap-3">
                        <Button onClick={increaseCursorSize} className="bg-transparent w-[55px] h-[55px] rounded-xl border-gray-300 hover:bg-black hover:fill-white" variant="outline-official">+</Button>
                        <Button onClick={decreaseCursorSize} className="bg-transparent w-[55px] h-[55px] rounded-lg border-gray-300 hover:bg-black hover:fill-white" variant="outline-official">-</Button>
                    </div>
                    <p className="font-semibold">IP Vault</p>
                    <VaultDropdown modelData={modelList} selectedModelId={selectedModelId} onSelect={handleSelectModel} />
                    {inpaintFiles.length > 0 && <div>
                        <p className="font-semibold">Select Image Asset&nbsp;
                            <Popover>
                                <PopoverTrigger>
                                    <InfoIcon />
                                </PopoverTrigger>
                                <PopoverContent className="text-base bg-brand-yellow border-none">
                                    Use this if you want to get a specific image asset to be blended into the main image.  Don&apos;t select anything if you want use the IP Vault and prompt to generate the infill
                                </PopoverContent>
                            </Popover>
                        </p>
                        <ImageDropdown files={inpaintFiles} selectedFileId={selectedInpaintFile?.id || ""} onSelect={handleSelectInpaintFile}/>
                    </div>}
                    <div className="flex gap-2">
                        <p className="font-semibold">Smart Touch-Up description</p>
                        <Popover>
                            <PopoverTrigger>
                                <InfoIcon />
                            </PopoverTrigger>
                            <PopoverContent className="text-base bg-brand-yellow border-none">
                                Any editing you do to this image will only the inpainted area
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className="relative w-[519px]">
                        <textarea
                            placeholder="Enter image prompt here"
                            className="bg-gray-100 rounded-lg p-4 text-black border-none h-[145px] w-full resize-none"
                            value={inpaintPrompt} onChange={(e) => setInpaintPrompt(e.target.value)}
                        />
                        {isPromptLoading ? (
                            <Button
                                className="w-44 border-none absolute hover:bg-brand-yellow bottom-5 right-5"
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                            >
                                <img className="h-4" src={loading} alt="Loading..." />
                            </Button>
                        ) : (
                            <Button
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                                className="flex gap-2 border-none w-44 hover:bg-black hover:text-white hover:fill-white absolute bottom-5 right-5"
                            >
                                <>
                                    <Sparkles />
                                    Rewrite
                                </>
                            </Button>
                        )}
                    </div>

                    <Button variant="primary-negative" onClick={triggerGenerateInpaint}>Generate inpainting</Button>
                </div>
            </div>
        </DialogModal>
    )

}

export default InpaintModal;

